export const Roll = {
  administrator: "administrator",
  worker: "worker",
  reader: "reader",
};

export const viewId = {
  header: "00001",
  sideMenu: "00002",
  footer: "00003",
  subHeader: "00004",
  login: "00101",
  logout: "00102",
  home: "00201",
  humanCapitalBasicInfo: "10101",
  humanCapitalConfiguration: "10102",
  humanResourcesManagement: "10103",
  hiringAndOnboarding: "10104",
  developAndRetention: "10105",
  monthMonitoring: "10106",
  iso30414: "10107",
  dei: "10108",
  healthManage: "10109",
  legalDisclosure: "10110",
  kpi: "10111",
  kgi: "10112",
  fileUpload: "10201",
  taskList: "10202",
  analysisData: "10203",
  dataUpdateStatus: "10204",
  accountingData: "10205",
  notice: "10301",
  news: "10302",
  changePassword: "10401",
  userList: "10402",
  userPermission: "10403",
  privacyPolicy: "10501",
  tos: "10502",
  dashboardList: "10601",
  myDashboard: "10701",
  kpiTargetSetting: "10801",
  kgiList: "10901",
  businessPlanNew: "10902",
  businessPlanEdit: "10903",
  authorityGroupList: "11001",
  authorityGroupInfo: "11002",
  benchmarkTargetList: "11101",
  benchmarkTargetInfo: "11102",
};

export const definedValue = {
  containerHeight: 500,
  footerHeight: 349,
};

export const titleName = {
  common: " | CHROFY HC",
  login: "ログイン",
  notFound: "ページが見つかりません",
  forbidden: "アクセスできません",
  top: "TOP",
  changePassword: "パスワード変更",
  fileUpload: "データアップロード",
  taskList: "データ更新",
  analysisData: "レポートデータ更新",
  accountingData: "財務データアップロード",
  dataUpdateStatus: "データ更新状況",
  news: "人的資本関連ニュース一覧",
  notice: "運営からのお知らせ一覧",
  dashboardList: "ダッシュボード一覧",
  myDashboard: "マイダッシュボード",
  kpiSetting: "人的資本KPI目標設定",
  userList: "ユーザー権限管理",
  userPermission: "ユーザー権限設定",
  kgiList: "経営戦略KGI管理",
  kgiInfo: "経営計画設定",
  kgiSetInfo: "中期経営計画設定",
  authorityGroupList: "権限グループ管理",
  authorityGroupInfo: "権限グループ設定",
  benchmarkTargetList: "ベンチマーク設定",
  benchmarkTarget: "ベンチマーク設定の登録・編集",
  /* privacyPolicy、TosはPathInfoListのStaticPageを参照 */
  /* ダッシュボードはPageInfoListのmainPageInfoListを参照 */
};

export const orderBy = {
  asc: "asc",
  desc: "desc",
};

export const removeDuplicates = {
  remove: "Y",
  notRemove: "N"
}

export const allConditions = {
  allBookmark: "すべてブックマーク",
  allCategory: "すべて用途タグ",
  allBadge: "すべてバッジ"
}

export const table = {
  AnalysisTargetInfo: "AnalysisTargetInfo",
  AnalysisCondition: "AnalysisCondition",
  AuthorityGroup: "AuthorityGroup",
  Bookmark: "Bookmark",
  BusinessPlan: "BusinessPlan",
  Category: "Category",
  CategoryTag: "CategoryTag",
  Company: "Company",
  DataCreateTask: "DataCreateTask",
  FileUploadTask: "FileUploadTask",
  AccountingUploadTask: "AccountingUploadTask",
  Kgi: "Kgi",
  KgiSet: "KgiSet",
  KpiActualInfo: "KpiActualInfo",
  KpiTargetInfo: "KpiTargetInfo",
  RelatedDashboard: "RelatedDashboard",
  SearchCondition: "SearchCondition",
  User: "User",
  BenchmarkTarget: "BenchmarkTarget",
}

export const analysisMode = {
  standalone: "standalone",
  consolidated: "consolidated"
}

export const restApiKey = {
  CHROFY_REST_API: "CHROFY_REST_API",
  CHROFY_REST_API_COMMON: "CHROFY_REST_API_COMMON"
}

export const operator = {
  GE: { name: "以上", value: "GE" },
  LE: { name: "以下", value: "LE" },
}

export const kpiUnit = {
  none: {
    name: "",
    numDecPlaces: 1,
  },
  yen: {
    name: "円",
    numDecPlaces: 0,
  },
  people: {
    name: "人",
    numDecPlaces: 0,
  },
  hour: {
    name: "時間",
    numDecPlaces: 2,
  },
  percent: {
    name: "%",
    numDecPlaces: 2,
  },
  times: {
    name: "回",
    numDecPlaces: 1,
  },
  items: {
    name: "件",
    numDecPlaces: 1,
  }
}

export const kpiOperator = {
  none: "指定なし",
  more: "以上",
  less: "以下",
}

export const kgiUnit = {
  none: {
    name: "単位なし",
    numDecPlaces: 1,
  },
  percent: {
    name: "%",
    numDecPlaces: 2,
  },
  people: {
    name: "人",
    numDecPlaces: 0,
  },
  times: {
    name: "回",
    numDecPlaces: 1,
  },
  items: {
    name: "件",
    numDecPlaces: 1,
  },
  hour: {
    name: "時間",
    numDecPlaces: 2,
  },
  yen: {
    name: "円",
    numDecPlaces: 0,
  },
  thousand_yen: {
    name: "千円",
    numDecPlaces: 0,
  },
  milion_yen: {
    name: "百万円",
    numDecPlaces: 0,
  },
  billion_yen: {
    name: "億円",
    numDecPlaces: 0,
  },
}

export const kgiStatus = {
  none: {
    name: "",
  },
  tempRegist: {
    name: "仮登録",
  },
  release: {
    name: "公開",
  },
  secret: {
    name: "非公開",
  },
}
export const periodKind = {
  monthly: {
    name: "月次",
    value: "monthly",
    unit: "月",
  },
  quarter: {
    name: "四半期",
    value: "quarter",
    unit: "期",
  },
  year: {
    name: "年次",
    value: "year",
    unit: "年度",
  },
}

export const taskStatus = {
  todo: {
    name: "作業待ち",
    value: "todo",
  },
  doing: {
    name: "作業中",
    value: "doing",
  },
  done: {
    name: "完了",
    value: "done",
  },
}

export const dataCreateStatus = {
  uncreated: {
    name: "アップロード待ち",
    value: "uncreated",
  },
  await: {
    name: "更新待ち",
    value: "await",
  },
  recreate: {
    name: "再更新待ち",
    value: "recreate",
  },
  creating: {
    name: "更新中",
    value: "creating",
  },
  created: {
    name: "更新済",
    value: "created",
  },
  error: {
    name: "更新エラー",
    value: "error",
  },
}

export const dataFixStatus = {
  fix: {
    name: "確定",
    value: "fix",
  },
  pending: {
    name: "未確定",
    value: "pending",
  },
}

export const operationKind = {
  create: {
    name: "データ更新",
    value: "create",
  },
  lock: {
    name: "データ確定",
    value: "lock",
  },
  unlock: {
    name: "データ確定解除",
    value: "unlock",
  },
}

export const fileUploadTaskStatus = {
  "unregistered": {
    name: "未登録",
    value: "unregistered",
  },
  "no-data": {
    name: "データ無し",
    value: "no-data",
  },
  "checking": {
    name: "チェック中",
    value: "checking",
  },
  "check-ok": {
    name: "チェック済",
    value: "check-ok",
  },
  "check-ng": {
    name: "チェックエラー",
    value: "check-ng",
  },
}

export const accountingUploadTaskStatus = {
  "unregistered": {
    name: "未登録",
    value: "unregistered",
  },
  "processing": {
    name: "処理中",
    value: "processing",
  },
  "registered": {
    name: "登録済",
    value: "registered",
  },
  "error": {
    name: "エラー",
    value: "error",
  },
}

export const listingClassification = {
  "public": {
    name: "上場",
    value: "1",
  },
  "private": {
    name: "非上場",
    value: "2",
  },
}

export const companySize = {
  "a": {
    name: "a 100人以下",
    value: "a",
  },
  "b": {
    name: "b 101～300人",
    value: "b",
  },
  "c": {
    name: "c 301～500人",
    value: "c",
  },
  "d": {
    name: "d 501～1000人",
    value: "d",
  },
  "e": {
    name: "e 1001～5000人",
    value: "e",
  },
  "f": {
    name: "f 5001人以上",
    value: "f",
  },
}
