"use strict";
import * as React from "react";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";

import { mainPageInfoList, monitoringInfoList } from "../../_constants/PageInfoList";
import { PathInfoList } from "../../_constants/PathInfoList";
import { errorLog } from "../../common/logger/Logger";
import { titleName, orderBy, table, analysisMode, restApiKey, listingClassification, companySize as companySizeCode } from "../../_constants/Code";
import { getPageText, getFileNameList, readDashboardId, getBenchmarkCompany, getIndustrySector } from "../../common/utils/FileUtils";
import { getDynamoDB } from "../../common/utils/DynamoDbUtils";
import { httpGet } from '../../services/api/AmplifyApi';
import { availabilityMiddleTitle, availabilitySmallTitle } from '../../common/utils/AnalysisModeUtil';

import Forbidden from "../../_components/Forbidden";
import Bookmark, { BookmarkEmpty } from "./control/Bookmark";
import CategoryTag, { CategoryTagEmpty } from "./control/CategoryTag";
import LoadingIcon from '../../assets/img/Loading/Loading.svg';

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

//@GlobalCustomizeFunction

class QuickSightContent extends React.Component {
  constructor(props) {
    super(props);
    //@Component
    this.yearRef = React.createRef();
    this.kpiYearRef = React.createRef();
    this.dashboard;
    this.mode = this.props.mode;
    this.dashboardAuthConf = this.props.dashboardAuthConf;
    this.authority = this.props.authority;
    this.initInx = (this.props.listNum ?
      this.props.listNum
      : this.dashboardList.findIndex((item, idx) => availabilityMiddleTitle(this.mode, this.dashboardAuthConf, item.dashboard, this.props.pageInfo.key + '.' + String(idx + 1))));
    this.initInxSub = this.props.listNumSub ?
      this.props.listNumSub
      : this.dashboardList[this.initInx].dashboard.findIndex((item, idx) => availabilitySmallTitle(this.mode, this.dashboardAuthConf, item, this.props.pageInfo.key + '.' + String(Number(this.initInx) + 1), idx));
    this.dashboardInfo = this.dashboardList[this.initInx].dashboard[this.initInxSub];
    this.kpiTargetInfo = [];
    this.relDashboardList = [];
    this.pageInfoList = mainPageInfoList.concat(monitoringInfoList);
    this.industrySector = [];
    this.companySize = [];
    this.state = {
      isLoaded: false,
      isDashboardLoaded: false,
      //@ComponentState
      titleIdx: Number(this.initInx),
      dashboardIdx: 0,
      dashboardIdxSub: Number(this.initInxSub),
      isOpenYear: false,
      year: '',
      month: '',
      isYear: true,
      isTotal: true,
      dashboardInfoList: {},
      pageText: null,
      toastMessage: null,
      kpiTargetInfoList: [],
      kpiTargetInfo: {},
      isOpenKpiYear: false,
      relDashboardList: [],
      benchmarkTarget: {},
      benchmarkTargetList: [],
      BookmarkContent: <BookmarkEmpty />,
      CategoryTagContent: <CategoryTagEmpty />,
    };
  }

  dashboardList = this.props.dashboardList;

  yearList = [];

  //@CustomizeFunction
  getYearList = async (currentYearMonth = null) => {
    try {
      const baseKey = process.env.REACT_APP_FOLDER_DATA_SET_BASE;
      let fileKey = this.isMonthly() ? process.env.REACT_APP_FOLDER_STANDARD_FORMAT_MONTH : process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL;
      let fileList = await getFileNameList(this.props.bucketName, baseKey + fileKey, orderBy.desc);

      if (process.env.REACT_APP_FOLDER_STANDARD_FORMAT_ANNUAL == fileKey) {
        // 最初のアンダースコアより前が数値のものを取り出し、"年度"をつける
        fileList = fileList
          .map((item) => {
            return item.split("_")[0];
          })
          .filter((item) => {
            return !isNaN(item);
          })
          .map((item) => {
            return item + "年度";
          });
        this.yearList = fileList;
        this.setState({
          year: (!currentYearMonth) ? this.yearList[0] : currentYearMonth.year,
          month: '',
        });
      } else if (process.env.REACT_APP_FOLDER_STANDARD_FORMAT_MONTH == fileKey) {
        fileList = fileList
          .map((item) => {
            return item.split(".")[0];
          })
          .filter((item) => {
            return !isNaN(item);
          })
          .map((item) => {
            if (item.length > 4) {
              let m = item.substring(4);
              item = item.substring(0, 4) + "年" + ((m.indexOf("0") == 0) ? m.substring(1) : m) + "月";
            }
            return item;
          });
        this.yearList = fileList;
        this.setState({
          year: (!currentYearMonth) ? this.yearList[0].substring(0, 5) : currentYearMonth.year,
          month: (!currentYearMonth) ? this.yearList[0].substring(5) : currentYearMonth.month,
        });
      }
    } catch (error) {
      errorLog(
        "E000001",
        this.props.userId,
        this.props.pageId,
        "getYearList",
        error
      );
    }
  };

  getDashboardId = async (isYear, isTotal, dashboardInfoList) => {
    const ids = dashboardInfoList[this.dashboardInfo.key][this.mode];
    const dashboardId = !this.dashboardInfo.quarterFlg || isYear
      ? ids.yearDashboardId : ids.quarterDashboardId;
    if (!Array.isArray(dashboardId) || dashboardId.length <= 1) {
      return dashboardId;
    } else {
      return isTotal ? dashboardId[0] : dashboardId[1];
    }
  }

  getParameters = async (year, month, kpiTargetInfo, benchmarkTarget) => {
    const categoryList = [
      this.props.pageInfo.key + ' ' + this.state.pageText[this.props.pageInfo.key].name,
      this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].name,
    ];
    let parameters = [
      {
        Name: "ownCorpNum",
        Values: [this.props.corporationNumber],
      },
      {
        Name: "year",
        Values: [year],
      }, {
        Name: "outputCategory",
        Values: [categoryList.join(' > ').replaceAll('&', '＆')]
      }, {
        Name: "categoryExplanation",
        Values: [this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx]?.categoryExplanation]
      }, {
        Name: "cloudFrontUrl",
        Values: [process.env.REACT_APP_URL_FRONT]
      },
    ];
    if (!this.props.isParent || this.mode == analysisMode.standalone) {
      parameters.push(
        {
          Name: "corpNum",
          Values: [this.props.corporationNumber],
        }
      )
    } else {
      // 権限に応じて表示制御する
      const refAuth = this.props.authority.referenceAuth;
      const targets = [];
      if (this.props.condition === "nothing") {
        // 分析対象が「すべて」の場合権限の会社をそのまま設定
        targets.push({
          Name: "corpNum", Values: refAuth.authCompany
        }, {
          Name: "segmentCode", Values: refAuth.authSegment
        });
      } else {
        // 分析対象が「すべて」以外は権限のない会社を除外
        const corp = this.props.targets.find(t => t.Name === "corpNum");
        const segment = this.props.targets.find(t => t.Name === "segmentCode");
        targets.push({
          Name: "corpNum", Values: corp.Values.filter(v => refAuth.authCompany.includes(v))
        }, {
          Name: "segmentCode", Values: segment.Values.filter(v => refAuth.authSegment.includes(v))
        });
      }
      targets.map((t) => {
        parameters.push(t);
      });
    }
    if (this.isMonthly()) {
      parameters.push({
        Name: "month",
        Values: [month],
      });
    };
    if (this.props.isKpi) {
      parameters.push({
        Name: "goal",
        Values: [kpiTargetInfo.value != undefined ? Number(kpiTargetInfo.value) : 0],
      });
    };
    if (this.props.isBenchmark && this.mode == analysisMode.standalone ? this.dashboardInfo?.benchmarkTarget.standalone : this.dashboardInfo?.benchmarkTarget.consolidate) {
      let cl, lc, is, cs;
      if (benchmarkTarget.ConditionInfo.ListingClassification.length) {
        lc = benchmarkTarget.ConditionInfo.ListingClassification.map(v => {
          const c = Object.values(listingClassification).find(item => item.value === v);
          return c ? c.name : "";
        });
      } else {
        lc = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.IndustrySector.length) {
        is = benchmarkTarget.ConditionInfo.IndustrySector
          .filter(v1 => this.industrySector.some(v2 => v1 == v2.Key))
          .map((v1) => {
            const data = this.industrySector.find(v2 => v1 == v2.Key);
            return data.Key + ' ' + data.Name;
          });
      } else {
        is = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.CompanySize.length) {
        cs = benchmarkTarget.ConditionInfo.CompanySize.map(v => this.companySize[v]);
      } else {
        cs = ['ALL_VALUES'];
      }
      if (benchmarkTarget.ConditionInfo.CompanyList.length) {
        const s3Data = await getBenchmarkCompany(
          this.props.corporationNumber,
          PathInfoList.Folder.benchmarkCompanyFolder + PathInfoList.Objectkeyname.benchmarkCompany,
          'CorporationNumber',
          benchmarkTarget.ConditionInfo.CompanyList
        );
        cl = s3Data.map(d => d.CorporationName);
      } else {
        cl = ['ALL_VALUES'];
      }
      parameters.push(
        {
          Name: "companyList",
          Values: cl,
        },
        {
          Name: "listingClassification",
          Values: lc,
        },
        {
          Name: "industrySector",
          Values: is,
        },
        {
          Name: "companySize",
          Values: cs,
        },
      );
    };
    return parameters;
  }

  changeAnalysisTargets(analysisTargets) {
    this.dashboard.setParameters(analysisTargets);
  }

  getQuickSightDashboardEmbedURL = async (isYear, isTotal, dashboardInfoList) => {
    this.setState({
      isDashboardLoaded: false,
    });
    //　ダッシュボードIDを取得
    const dashboardId = await this.getDashboardId(isYear, isTotal, dashboardInfoList);
    try {
      // EmbedURL取得
      Auth.currentAuthenticatedUser().then(async (user) => {
        const init = {
          queryStringParameters: {
            userName: user.username,
            dashboardId: dashboardId, // TODO ダッシュボードの特定
          },
          headers: {},
        };
        httpGet(
          restApiKey.CHROFY_REST_API,
          PathInfoList.Resource.quickSightEmbedUrl(this.props.corporationNumber),
          init
        )
          .then(async (res) => {
            const containerDiv = document.getElementById("embeddingContainer");
            if (!res.EmbedUrl.startsWith("Error generating embedding url:")) {
              if (containerDiv != null) {
                containerDiv.innerHTML = "";
              }
              const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
              const frameOptions = {
                url: res.EmbedUrl,
                container: containerDiv,
                height: "AutoFit",
                width: "100%",
                resizeHeightOnSizeChangedEvent: true,
                onChange: (changeEvent, metadata) => {
                  switch (changeEvent.eventName) {
                    case 'FRAME_LOADED': {
                      this.setState({
                        isDashboardLoaded: true,
                      });
                      break;
                    }
                  }
                },
              };
              const contentOptions = {
                parameters: paramObj,
                locale: "ja-JP",
                sheetOptions: {
                  emitSizeChangedEventOnSheetChange: true,
                },
                toolbarOptions: {
                  export: true,
                },
                attributionOptions: {
                  overlayContent: true,
                },
                onMessage: async (messageEvent, experienceMetadata) => {
                  switch (messageEvent.eventName) {
                    case 'ERROR_OCCURRED': {
                      this.setState({
                        isDashboardLoaded: true,
                      });
                      break;
                    }
                  }
                },
              };
              const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
              this.dashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions);
            } else {
              containerDiv.innerHTML =
                "ダッシュボードのURL取得に失敗しました。画面を再表示してください。";
            }
          })
          .catch((err) => {
            this.setState({
              isDashboardLoaded: true,
            });
            throw new Error(err);
          });
      });
    } catch (err) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getQuickSightDashboardEmbedURL", err);
      this.setState({
        isDashboardLoaded: true,
      });
    }
  };

  changeTitle = (value) => {
    if (this.state.titleIdx !== value) {
      this.setState({
        titleIdx: value,
      });
      const idxSub = this.dashboardList[value].dashboard.findIndex((item, idx) => availabilitySmallTitle(this.mode, this.dashboardAuthConf, item, this.props.pageInfo.key + '.' + String(Number(value) + 1), idx));
      this.changeDashboard(true, value, idxSub, this.dashboardList[value].dashboard[idxSub]);
    }
  };

  changeDashboard = async (isChangeTitle, idx, index, value) => {
    try {
      if (isChangeTitle || this.state.dashboardIdxSub !== index) {
        const totalFlg = this.getTotalFlg(this.state.isYear, idx, index)
        this.setState({
          dashboardIdxSub: index,
          isDashboardLoaded: false,
          isTotal: totalFlg,
          BookmarkContent: <BookmarkEmpty />,
          CategoryTagContent: <CategoryTagEmpty />,
        });
        this.dashboardInfo = value;
        if (this.props.isKpi) {
          this.setKpiTargetInfoList();
          this.setRelDashboardList();
        }
        //　ダッシュボードIDを取得
        const dashboardId = await this.getDashboardId(this.state.isYear, totalFlg, this.state.dashboardInfoList);
        const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
        const options = {
          parameters: paramObj,
        };
        await this.dashboard.navigateToDashboard(dashboardId, options);
        this.setState({
          BookmarkContent: <Bookmark
            dashboardKey={this.dashboardInfo.key}
            dashboardName={this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].name}
            pageId={this.props.pageId} />,
          CategoryTagContent: <CategoryTag
            dashboardKey={this.dashboardInfo.key}
            dashboardName={this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].name}
            pageId={this.props.pageId} />
        });
        this.setState({
          isDashboardLoaded: true,
        });
      }
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeDashboard", error);
      this.setState({
        isDashboardLoaded: true,
      });
    }
  };

  setIsOpenKpiYear = (isOpen) => {
    this.setState({
      isOpenKpiYear: isOpen,
    });
    const kpiYearButton = document.getElementById("kpiYearButton");
    if (isOpen) {
      kpiYearButton.classList.add('is-active');
    } else {
      kpiYearButton.classList.remove('is-active');
    }
  };

  setIsOpenYear = (isOpen) => {
    if (this.dashboardInfo.yearFilterFlg) {
      this.setState({
        isOpenYear: isOpen,
      });
      const yearButton = document.getElementById("yearButton");
      if (isOpen) {
        yearButton.classList.add('is-active');
      } else {
        yearButton.classList.remove('is-active');
      }
    }
  };

  changeKpiYear = async (value) => {
    try {
      this.setState({
        kpiTargetInfo: value,
      });
      this.setIsOpenKpiYear(false);
      const paramObj = await this.getParameters(this.state.year, this.state.month, value, this.state.benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYear", error);
    }
  };

  changeYear = async (value) => {
    try {
      let yearVal = value;
      let monthVal = '';
      if (this.isMonthly()) {
        yearVal = value.substring(0, 5);
        monthVal = value.substring(5);
      }
      this.setState({
        year: yearVal,
        month: monthVal,
      });
      this.setIsOpenYear(false);
      const paramObj = await this.getParameters(yearVal, monthVal, this.state.kpiTargetInfo, this.state.benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYear", error);
    }
  };

  changeYearQuarter = async (isYear) => {
    try {
      this.setState({
        isYear: isYear,
        isTotal: this.getTotalFlg(isYear, this.state.titleIdx, this.state.dashboardIdxSub),
      });
      //　ダッシュボードIDを取得
      const dashboardId = await this.getDashboardId(isYear, this.state.isTotal, this.state.dashboardInfoList);
      const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
      const options = {
        parameters: paramObj,
      };
      await this.dashboard.navigateToDashboard(dashboardId, options);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeYearQuarter", error);
    }
  };

  changeTotalIndividual = async (isTotal) => {
    try {
      this.setState({
        isTotal: isTotal,
      });
      //　ダッシュボードIDを取得
      const dashboardId = await this.getDashboardId(this.state.isYear, isTotal, this.state.dashboardInfoList);
      const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, this.state.benchmarkTarget);
      const options = {
        parameters: paramObj,
      };
      await this.dashboard.navigateToDashboard(dashboardId, options);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeTotalIndividual", error);
    }
  }

  isIncludeQuater = (list) => {
    for (const item of list) {
      if (item.quarterFlg) {
        return true;
      }
    }
    return false;
  }

  dashboardKeySort = (a, b) => {
    // 現状は関連ダッシュボードのソートのみに使用するため数値の比較のみ
    // 大見出しでソート
    const aLKey = parseFloat(a.split(".")[0]);
    const bLKey = parseFloat(b.split(".")[0]);
    if (aLKey != bLKey) {
      if (aLKey > bLKey) return 1;
      if (aLKey < bLKey) return -1;
    }
    // 中見出しでソート
    const aMKey = parseFloat(a.split(".")[1]);
    const bMKey = parseFloat(b.split(".")[1]);
    if (aMKey != bMKey) {
      if (aMKey > bMKey) return 1;
      if (aMKey < bMKey) return -1;
    }
    // 小見出しでソート
    const aSKey = parseFloat(a.split(".")[2]);
    const bSKey = parseFloat(b.split(".")[2]);
    if (aSKey != bSKey) {
      if (aSKey > bSKey) return 1;
      if (aSKey < bSKey) return -1;
    }
    return 0;
  }

  getRelDashboard = async () => {
    try {
      this.relDashboardList = await getDynamoDB(
        table.RelatedDashboard,
        null,
        this.props.corporationNumber
      );
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getRelDashboard", error);
    }
  };

  setRelDashboardList = () => {
    try {
      let relDashboardList = [];
      if (this.relDashboardList.length > 0) {
        relDashboardList =
          this.relDashboardList
            .filter(v => v.DashboardKey == this.dashboardInfo.key)
            .filter((v) => {
              const largeKey = (v.RelDashboardKey.split(".")[0]); // 大見出しのキー
              const mIdx = (v.RelDashboardKey.split(".")[1]) - 1; // 中見出しの要素
              const sIdx = (v.RelDashboardKey.split(".")[2]) - 1; // 小見出しの要素
              const pageInfo = this.pageInfoList.find(page => { return page.key === largeKey });
              if (this.mode == analysisMode.standalone) {
                return pageInfo.dashboardList[mIdx].dashboard[sIdx].activeFlg;
              } else if (this.mode == analysisMode.consolidated) {
                return pageInfo.dashboardList[mIdx].dashboard[sIdx].groupFlg;
              }
            })
            .map((v) => { return v.RelDashboardKey })
            .sort((a, b) => this.dashboardKeySort(a, b));
      }
      this.setState({
        relDashboardList: relDashboardList,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "setRelDashboardList", error);
    }
  };

  getKpiTargetInfo = async () => {
    try {
      this.kpiTargetInfo = await getDynamoDB(
        table.KpiTargetInfo,
        null,
        this.props.corporationNumber,
        { Mode: this.mode }
      );
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getKpiTargetInfo", error);
    }
  };

  setKpiTargetInfoList = () => {
    try {
      let kpiTargetInfoList = [];
      let kpiTargetInfo = {};
      if (this.kpiTargetInfo.length > 0) {
        let kpiKeyIdx = this.dashboardInfo.key.indexOf(".") + 1;
        let array = this.kpiTargetInfo[0].TargetValue.find(v => v.Key == this.dashboardInfo.key.substring(kpiKeyIdx));
        if (array != undefined) {
          array = array.Value;
          for (const [k, v] of Object.entries(array)) {
            if (v.Value != "") {
              kpiTargetInfoList.push({ "year": k, "value": v.Value });
            }
          }
          if (kpiTargetInfoList.length > 0) {
            kpiTargetInfoList.sort((a, b) => {
              return (a["year"] > b["year"]) ? 1 : -1;
            });
            kpiTargetInfo = kpiTargetInfoList[0];
          }
        }
      }
      this.setState({
        kpiTargetInfoList: kpiTargetInfoList,
        kpiTargetInfo: kpiTargetInfo,
      });
      return kpiTargetInfo;
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "setKpiTargetInfoList", error);
    }
  };

  getBenchmarkTarget = async () => {
    const initData = {
      Key: 0,
      Title: '条件指定なし',
      ConditionInfo: {
        CompanyList: [],
        ListingClassification: [],
        IndustrySector: [],
        CompanySize: []
      }
    };
    try {
      let benchmarkTarget = await getDynamoDB(
        table.BenchmarkTarget,
        null,
        this.props.corporationNumber,
        null,
        null
      );
      // ユーザマスタを取得
      let user = await getDynamoDB(
        table.User,
        null,
        this.props.corporationNumber,
        null,
        null
      );
      if (benchmarkTarget.length > 0) {
        const l1 = benchmarkTarget.filter(l => user.find(u => l.UserId === u.UserId)); // 退職者を除く
        const l2 = l1.filter(l => l.UserId === this.props.userId) // 自身が登録した条件
          .sort((a, b) => {
            if (a.Title > b.Title) return 1;
            if (b.Title > a.Title) return -1;
            return 0;
          });
        const l3 = l1.filter(l => l.UserId !== this.props.userId && l.SharedFlag) // 他者が共有した条件（名称に[共有]を付ける）
          .map(l => { return { ...l, Title: l.Title + '[共有]' } })
          .sort((a, b) => {
            if (a.Title > b.Title) return 1;
            if (b.Title > a.Title) return -1;
            return 0;
          });
        benchmarkTarget = l2.concat(l3).map(l => { return { Key: l.Key, Title: l.Title, ConditionInfo: l.ConditionInfo } });
      }
      benchmarkTarget.unshift(initData);
      this.setState({
        benchmarkTarget: initData,
        benchmarkTargetList: benchmarkTarget,
      });
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getBenchmarkTarget", error);
    }
  };

  getBenchmarkIndustrySector = async () => {
    try {
      let type;
      // 女活データの場合 type=2、財務データの場合 type=3
      if (this.props.pageInfo.key == '10') {
        type = '2';
      } else {
        type = '3';
      }
      this.industrySector = await getIndustrySector(this.props.corporationNumber, type);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "getBenchmarkIndustrySector", error);
    }
  };

  changeBenchmarkTarget = async (value) => {
    const benchmarkTarget = this.state.benchmarkTargetList.find(l => l.Key === parseInt(value));
    try {
      this.setState({
        benchmarkTarget: benchmarkTarget,
      });
      const paramObj = await this.getParameters(this.state.year, this.state.month, this.state.kpiTargetInfo, benchmarkTarget);
      this.dashboard.setParameters(paramObj);
    } catch (error) {
      errorLog("E000001", this.props.userId, this.props.pageId, "changeBenchmarkTarget", error);
    }
  }

  componentDidMount = async () => {
    await this.getYearList();
    if (this.props.isKpi) {
      await this.getKpiTargetInfo();
      this.setKpiTargetInfoList();
      await this.getRelDashboard();
      this.setRelDashboardList();
    }
    if (this.props.isBenchmark) {
      this.companySize = Object.keys(companySizeCode).reduce((l, key) => {
        l[key] = companySizeCode[key].name;
        return l;
      }, {});
      await this.getBenchmarkIndustrySector();
      await this.getBenchmarkTarget();
    }
    const dashboardInfoList = await readDashboardId(this.props.bucketName);
    const pageText = await getPageText();
    this.setState({
      dashboardInfoList: dashboardInfoList,
      pageText: pageText,
    });
    this.getQuickSightDashboardEmbedURL(this.state.isYear, this.state.isTotal, dashboardInfoList);
    this.setState({
      BookmarkContent: <Bookmark
        dashboardKey={this.dashboardInfo.key}
        dashboardName={pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].name}
        pageId={this.props.pageId} />,
      CategoryTagContent: <CategoryTag
        dashboardKey={this.dashboardInfo.key}
        dashboardName={pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].name}
        pageId={this.props.pageId} />
    });
    this.setState({
      isLoaded: true,
    });
    window.scrollTo(0, 0);
  };

  isMonthly = () => {
    let rs = false;
    const keyFirst = this.dashboardInfo.key.substring(0, 1);
    switch (keyFirst) {
      case '6':
        rs = true;
        break;
    }
    return rs;
  };

  getRelDashboardInfo = (key) => {
    const largeKey = (key.split(".")[0]); // 大見出しのキー
    const mIdx = (key.split(".")[1]) - 1; // 中見出しの要素
    const sIdx = (key.split(".")[2]) - 1; // 小見出しの要素
    const pageInfo = this.pageInfoList.find(page => { return page.key === largeKey; });
    const url = pageInfo.url + (mIdx) + "/" + (sIdx);
    const pageText = this.state.pageText[largeKey];
    const name = pageText.dashboardList[mIdx].dashboard[sIdx].name + (pageInfo.dashboardList[mIdx].dashboard[sIdx].quarterFlg ? " [Y/Q]" : "");
    // 参照権限があるか判定
    let availability = false;
    if (this.authority.Dashboard_AuthInfo.Availability) {  // ダッシュボードへの参照権限がある
      let authConf = this.authority.Dashboard_AuthInfo.Auth_Conf.find(auth => auth.Key === largeKey);
      if (authConf?.Availability) {  // 大見出しレベルで参照権限がある
        const mDashboardId = largeKey + '.' + String(mIdx + 1);
        const dashboardList = authConf.DashboardList.find(d => d.Key === mDashboardId);
        if (dashboardList?.Availability) {  // 中見出しレベルで参照権限がある
          const sDashboardId = mDashboardId + '.' + (sIdx + 1);
          const authDashboard = dashboardList.Dashboard.find(d => d === sDashboardId);
          if (authDashboard) {  // 小見出しレベルで参照権限がある
            availability = true;
          }
        }
      }
    }
    return { "name": name, "url": url, "availability": availability };
  };

  getTotalFlg = (isYear, titleIdx, idxSub) => {
    const d = this.dashboardList[titleIdx].dashboard[idxSub]
    if (isYear || !d.quarterFlg) {
      return d.consolidatedYear.totalFlg;
    } else {
      return d.consolidatedQuarter.totalFlg;
    }
  }

  getIndividualFlg = (isYear, titleIdx, idxSub) => {
    const d = this.dashboardList[titleIdx].dashboard[idxSub]
    if (isYear || !d.quarterFlg) {
      return d.consolidatedYear.individualFlg;
    } else {
      return d.consolidatedQuarter.individualFlg;
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    // タイトル設定
    document.title = this.state.pageText[this.props.pageInfo.key].name + titleName.common;
    return (
      <React.Fragment>
        {/* コンテンツヘッダ */}
        <header className="category-page-header flex items-center my-6">
          {this.props.pageInfo.img && <div className="category-page-header__img ml-4 mr-8"><img src={this.props.pageInfo.img} alt="" /></div>}
          <div className={`pl-8 relative ${this.props.pageInfo.img ? ' category-page-header__text' : ''}`}>
            <div className="category-page-header__subtitle flex items-center red--text">
              {this.state.pageText[this.props.pageInfo.key].id && this.props.pageInfo.monitoring == null && <span className="category-page-header__subtitle-number text-h4-en">{this.state.pageText[this.props.pageInfo.key].id}</span>}
              {this.state.pageText[this.props.pageInfo.key].nameEng && <span className="category-page-header__subtitle-text text-h6-en ml-7">{this.state.pageText[this.props.pageInfo.key].nameEng}</span>}
            </div>
            <h1 className="category-page-header__title">
              <span className="category-page-header__title-text text-h2">{this.props.pageInfo.monitoring && <span>{this.state.pageText[this.props.pageInfo.key].id} </span>}{this.state.pageText[this.props.pageInfo.key].name}</span>
            </h1>
            <p className="category-page-header__description text-caption-2 grey--text text--lighten-70">{this.state.pageText[this.props.pageInfo.key].explanation}</p>
          </div>
        </header>
        {/* コンテンツボディ */}
        <div className="page-body">
          {/* ===== カテゴリーナビゲーション ===== */}
          {/*
                  ■ナビゲーションのアクティブ表示について
                  選択しているカテゴリーのli要素に is-active クラスを入れてください
                  ■ナビゲーションの非活性表示について
                  カテゴリーのli要素に is-disabled クラスを入れてください
                */}
          {this.dashboardList.length > 1
            ? <ul className="nav-category grey rounded-t-lg flex justify-center flex-wrap">
              {this.dashboardList.map((item, index) => {
                // 権限制御
                let availability = false;
                const mDashboardId = this.props.pageInfo.key + '.' + String(index + 1);
                const middleFlg = availabilityMiddleTitle(this.mode, this.dashboardAuthConf, this.props.pageInfo.dashboardList[index].dashboard, mDashboardId);
                if (middleFlg) {
                  const smallFlg = this.props.pageInfo.dashboardList[index].dashboard.findIndex((item, idx) => availabilitySmallTitle(this.mode, this.dashboardAuthConf, item, mDashboardId, idx));
                  if (smallFlg !== -1) {
                    availability = true;
                  }
                }
                return (
                  <li
                    key={index}
                    className={`
                            nav-category__item
                            ${this.state.titleIdx === index ? ' is-active' : ''}
                            ${!this.state.isDashboardLoaded ? ' nav-category__item-load' : ''}
                            ${availability ? '' : 'is-disabled'}
                          `}
                  >
                    <button
                      type="button"
                      className={`
                              nav-category__btn
                              text-h6 px-4 py-3 mx-6
                            `}
                      disabled={!this.state.isDashboardLoaded}
                      onClick={() => this.changeTitle(index)}
                    >
                      {this.state.pageText[this.props.pageInfo.key].dashboardList[index].name}{this.isIncludeQuater(item.dashboard) ? " [Y/Q]" : ""}
                    </button>
                  </li>
                )
              })}
            </ul>
            : <div className="nav-category grey rounded-lg flex justify-center nav-category-empty"></div>
          }
          {/* ===== /カテゴリーナビゲーション ===== */}
          {/* ===== グラフナビゲーション ===== */}
          {/*
                  ■ナビゲーションのアクティブ表示について
                  選択しているカテゴリーのli要素に is-active クラスを入れてください
                  ■ナビゲーションの非活性表示について
                  カテゴリーのli要素に is-disabled クラスを入れてください
                */}
          <div className="box-graph__header pt-4 pb-5 px-2 rounded-b-lg white mb-8">
            <ul className="nav-graph flex flex-wrap pb-0.5 pb-px">
              {this.dashboardList[this.state.titleIdx].dashboard.map((item, index) => {
                const dashboardName = this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[index].name;
                // 権限制御
                const mDashboardId = this.props.pageInfo.key + '.' + String(this.state.titleIdx + 1);
                const sDashboardId = mDashboardId + '.' + String(index + 1);
                const dashboardList = this.dashboardAuthConf.DashboardList.find(d => d.Key === mDashboardId);
                const dashboard = dashboardList?.Dashboard.find(d => d === sDashboardId);
                return (
                  <div key={index}>
                    <li className={`
                            nav-graph__item
                            ${this.state.dashboardIdxSub === index ? ' is-active' : ''}
                            ${!this.state.isDashboardLoaded ? ' nav-graph__item-load' : ''}
                            ${(this.mode == analysisMode.consolidated && !item.groupFlg) || (this.mode == analysisMode.standalone && !item.activeFlg) || !dashboard ? ' is-disabled' : ''}
                          `}>
                      <button type="button" className="nav-graph__btn text-h6 px-2 py-1.5 mx-4 my-1" disabled={!this.state.isDashboardLoaded} onClick={() => this.changeDashboard(false, this.state.titleIdx, index, item)}>
                        <span className="nav-graph__text">{dashboardName}{item.quarterFlg ? " [Y/Q]" : ""}</span>
                        {this.state.isDashboardLoaded && <span className="nav-graph__preview"><img src={item.img} alt="" /></span>}
                      </button>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
          {/* ===== /グラフナビゲーション ===== */}

          <div className="box-graph rounded-2xl white">
            {/* ===== カテゴリータイトルとコントロール ===== */}
            <div className="dashboard-title-area flex justify-between items-center pl-10 pr-8">
              {/* ===== カテゴリータイトル ===== */}
              <div className="dashboard-title py-8">
                <h2 className="dashboard-title__name text-h3">{this.state.pageText[this.props.pageInfo.key].dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].name}</h2>
              </div>
              {/* ===== /カテゴリータイトル ===== */}
              {/* ===== コントロール ===== */}
              <div className="action-area flex">
                {/* ブックマーク */}
                {this.state.BookmarkContent}
                {/* 用途タグ */}
                {this.state.CategoryTagContent}
                {/* 年度選択 */}
                <div className="action-area__year-select">
                  <div className="year-container" tabIndex="0" ref={this.yearRef} onBlur={() => this.setIsOpenYear(false)}>
                    {/*
                            ■yearButtonについて
                            クリックしてドロップダウンリストを開いた状態は yearButton クラスに is-active クラスを追加してください。
                            <div className="yearButton is-active flex items-center justify-between rounded-xl pl-4 pr-2">

                            disabled状態は yearButton クラスに is-disabled クラスを追加してください。
                            <div className="yearButton is-disabled flex items-center justify-between rounded-xl pl-4 pr-2">
                          */}
                    <div id="yearButton" className={`yearButton flex items-center justify-between rounded-xl pl-4 pr-2 ${(!this.state.isDashboardLoaded || !this.dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].yearFilterFlg) ? ' is-disabled' : ''}`} onClick={() => this.setIsOpenYear(!this.state.isOpenYear)}>
                      <div className="yearButton__text text-h7 mr-2">{this.state.year + this.state.month}</div>
                      <div className="yearButton__icon flex items-center justify-center w-8 h-8"><span className="icon-arrow"></span></div>
                    </div>
                    {/*
                            通常は
                            <ul className="year" hidden>
                          */}
                    <ul className="year rounded-xl" hidden={!this.state.isOpenYear || !this.state.isDashboardLoaded}>
                      {this.yearList.map((value, index) => {
                        return (
                          <li key={index} className="item text-h7" onClick={() => this.changeYear(value)}> {value} </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                {/* 年度/四半期選択 */}
                <div className="action-area__year-quarter ml-4">
                  <div className="radioArea rounded-xl flex gap-x-2" id="makeImg">
                    <input type="radio" name="yearQuarter" id="year" onClick={() => this.changeYearQuarter(true)} defaultChecked={this.state.isYear} className="hidden" disabled={!this.state.isDashboardLoaded || !this.dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].quarterFlg} />
                    <label htmlFor="year" className="text-h7 rounded">年度</label>
                    <input type="radio" name="yearQuarter" id="quarter" onClick={() => this.changeYearQuarter(false)} defaultChecked={!this.state.isYear} className="hidden" disabled={!this.state.isDashboardLoaded || !this.dashboardList[this.state.titleIdx].dashboard[this.state.dashboardIdxSub].quarterFlg} />
                    <label htmlFor="quarter" className="text-h7 rounded">四半期</label>
                  </div>
                </div>
                {/* 合算/内訳選択 */}
                {(() => {
                  const content = (isYear) => {
                    const totalFlg = this.getTotalFlg(isYear, this.state.titleIdx, this.state.dashboardIdxSub)
                    const individualFlg = this.getIndividualFlg(isYear, this.state.titleIdx, this.state.dashboardIdxSub)
                    return (
                      <div className="action-area__total-individual ml-4">
                        <div className="radioArea rounded-xl flex gap-x-2" id="makeImg">
                          <input type="radio" name="totalIndividual" id="total" onClick={() => this.changeTotalIndividual(true)} checked={(this.state.isTotal && totalFlg) || !individualFlg} className="hidden" disabled={!this.state.isDashboardLoaded || !totalFlg} />
                          <label htmlFor="total" className="text-h7 rounded">合算</label>
                          <input type="radio" name="totalIndividual" id="individual" onClick={() => this.changeTotalIndividual(false)} checked={(!this.state.isTotal && individualFlg) || !totalFlg} className="hidden" disabled={!this.state.isDashboardLoaded || !individualFlg} />
                          <label htmlFor="individual" className="text-h7 rounded">内訳</label>
                        </div>
                      </div>
                    )
                  };
                  // 連結モード時に表示する
                  if (this.mode === analysisMode.consolidated) {
                    return content(this.state.isYear);
                  }
                })()}
              </div>
              {/* ===== /コントロール ===== */}
            </div>
            {/* ===== /カテゴリータイトルとコントロール ===== */}

            {/* S2_人的資本KPI画面もしくは10_法廷開示レポート画面で表示する */}
            {(this.props.isKpi || this.props.isBenchmark) &&
              <div className="box-graph__middle w-full flex justify-center items-center">
                <ul className="nav-graph flex flex-wrap pt-6 pb-px">
                  {this.props.isKpi &&
                    <li className="nav-graph__item px-4">
                      {/* 目標値の対象年度 */}
                      {/* 目標値の対象年度の表示・設定
                          目標未設定時は、box-graph__target-fiscal-yearクラスにhiddenクラスを追加してください
                      */}
                      <div className="box-graph__target-fiscal-year flex justify-center items-center">
                        <div className="text-h5">目標値の対象年度：</div>
                        {/* 年度選択 */}
                        {this.state.kpiTargetInfoList.length > 0 &&
                          <div className="action-area">
                            <div className="year-container" tabIndex="0" ref={this.yearRef} onBlur={() => this.setIsOpenKpiYear(false)}>
                              {/*
                                ■yearButtonについて
                                クリックしてドロップダウンリストを開いた状態は yearButton クラスに is-active クラスを追加してください。
                                <div className="yearButton is-active flex items-center justify-between rounded-xl pl-4 pr-2">
          
                                disabled状態は yearButton クラスに is-disabled クラスを追加してください。
                                <div className="yearButton is-disabled flex items-center justify-between rounded-xl pl-4 pr-2">
                              */}
                              <div id="kpiYearButton" className={`yearButton flex items-center justify-between rounded-xl pl-4 pr-2 ${!this.state.isDashboardLoaded ? ' is-disabled' : ''}`} style={{ paddingTop: "4px", paddingBottom: "4px" }} onClick={() => this.setIsOpenKpiYear(!this.state.isOpenKpiYear)}>
                                <div className="yearButton__text text-h7 mr-2">{this.state.kpiTargetInfo.year} {this.props.accPeriod}期</div>
                                <div className="yearButton__icon flex items-center justify-center w-8 h-8"><span className="icon-arrow"></span></div>
                              </div>
                              {/*
                                通常は
                                <ul className="year" hidden>
                              */}
                              <ul className="year rounded-xl" hidden={!this.state.isOpenKpiYear || !this.state.isDashboardLoaded}>
                                {this.state.kpiTargetInfoList.map((value, index) => {
                                  return (
                                    <li key={index} className="item text-h7" onClick={() => this.changeKpiYear(value)}> {value.year} {this.props.accPeriod}期 </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        }
                        {this.state.kpiTargetInfoList.length == 0 &&
                          <div className="text-h5 red--text py-2">目標値未設定</div>
                        }
                      </div>
                    </li>
                  }
                  {/* /目標値の対象年度 */}
                  {(() => {
                    {/* ベンチマーク対象 */ }
                    if (this.props.isBenchmark) {
                      // 設定ファイルからベンチマーク対象プルダウンを表示するか判定
                      const isVisible = this.mode == analysisMode.standalone ? this.dashboardInfo?.benchmarkTarget.standalone : this.dashboardInfo?.benchmarkTarget.consolidate;
                      if (isVisible) {
                        return (
                          <li className={"nav-graph__item"}>
                            <div className="box-graph__target-fiscal-year flex justify-end items-center px-4">
                              <div className="form-control text-nowrap">
                                <div className="select-control flex justify-center items-center">
                                  <div className="text-h5">ベンチマーク対象：</div>
                                  <select className="form-select select-p-large rounded-lg text-h7 pr-10" style={{ width: "200px" }} value={this.state.benchmarkTarget.Key}
                                    onChange={(e) => this.changeBenchmarkTarget(e.target.value)}
                                    disabled={!this.state.isDashboardLoaded}>
                                    {this.state.benchmarkTargetList.map((bt, idx) => {
                                      return <option key={idx} value={bt.Key}>{bt.Title}</option>
                                    })}
                                  </select>
                                  <div className="select-control__icon flex text-base items-center justify-center w-8 absolute pointer-events-none inset-y-0 right-0">
                                    <span className="icon-arrow rotate-90"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    }
                  })()}
                  {/* /ベンチマーク対象 */}
                </ul>
              </div>
            }

            {/* ===== グラフ ===== */}
            <div className="box-graph__body">
              <div id="embeddingContainer">
                <div className="py-8">
                  <div className="dashboard-loading">
                    <img src={LoadingIcon} alt="LoadingIcon" />
                  </div>
                </div>
              </div>
            </div>
            {/* ===== /グラフ ===== */}
          </div>
          {/* /box-graph */}

          {/* 関連コンテンツ */}
          {this.props.isKpi &&
            <div className="box-graph rounded-2xl white -mt-px pt-12 pb-16">
              <h2 className="text-h3 text-center mb-6">関連ダッシュボード</h2>
              <ul className="nav-graph flex flex-wrap justify-center pb-0.5 pb-px mx-32">
                {this.state.relDashboardList.map((item, index) => {
                  let relDashboardInfo = this.getRelDashboardInfo(item);
                  return (
                    <li key={index} className={`nav-graph__item ${!relDashboardInfo.availability ? "is-disabled" : ""}`}>
                      <button type="button" className="nav-graph__btn text-h6 px-2 py-1.5 mx-4 my-1">
                        {(() => {
                          const label = <span className="nav-graph__text">{relDashboardInfo.name}</span>;
                          if (relDashboardInfo.availability) {
                            return (
                              <Link underline="none" href={relDashboardInfo.url} target="_blank">{label}</Link>
                            );
                          } else {
                            return label;
                          }
                        })()}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          }
          {/* /関連コンテンツ */}
        </div>
      </React.Fragment>
    );
  }
}

const QuickSight = (props) => {
  const userState = useSelector((state) => state.user);
  const companyState = useSelector((state) => state.company);
  const analysisState = useSelector((state) => state.analysis);
  const authorityState = useSelector((state) => state.authority);
  const isFirstRender = React.useRef(true);
  const childRef = React.createRef();

  // 権限制御
  let availability = false;
  let dashboardAuthConf;
  if (authorityState.Dashboard_AuthInfo.Availability) {  // ダッシュボードへの参照権限がある
    dashboardAuthConf = authorityState.Dashboard_AuthInfo.Auth_Conf.find(auth => auth.Key === props.pageInfo.key);
    if (dashboardAuthConf?.Availability) {  // 大見出しレベルで参照権限がある
      if (props.params.listNum === undefined) {
        availability = true;
      } else {
        const mDashboardId = props.pageInfo.key + '.' + String(Number(props.params.listNum) + 1);
        const dashboardList = dashboardAuthConf.DashboardList.find(d => d.Key === mDashboardId);
        if (dashboardList?.Availability) {  // 中見出しレベルで参照権限がある
          if (props.params.listNumSub === undefined) {
            availability = true;
          } else {
            const sDashboardId = mDashboardId + '.' + (Number(props.params.listNumSub) + 1);
            const authDashboard = dashboardList.Dashboard.find(d => d === sDashboardId);
            if (authDashboard) {  // 小見出しレベルで参照権限がある
              const dashboard = props.pageInfo.dashboardList[props.params.listNum].dashboard[props.params.listNumSub];
              if (
                (analysisState.mode === analysisMode.standalone && dashboard.activeFlg)
                || (analysisState.mode === analysisMode.consolidated && dashboard.groupFlg)
              ) {
                availability = true;
              }
            }
          }
        }
      }
    }
  }

  React.useEffect(() => {
    // 初回レンダリング時は実行しない
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    childRef.current.changeAnalysisTargets(analysisState.targets);
  }, [analysisState]);

  if (availability) {
    return (
      <QuickSightContent
        pageId={props.pageId}
        pageInfo={props.pageInfo}
        dashboardList={props.dashboardList}
        listNum={props.params.listNum}
        listNumSub={props.params.listNumSub}
        isKpi={props.isKpi}
        isBenchmark={props.isBenchmark}
        userId={userState.sub}
        corporationNumber={companyState.corporationNumber}
        accPeriod={companyState.accPeriod}
        bucketName={"chrofy-" + process.env.REACT_APP_ENV + "-" + companyState.corporationNumber}
        mode={analysisState.mode}
        condition={analysisState.condition}
        targets={analysisState.targets}
        authority={authorityState}
        dashboardAuthConf={dashboardAuthConf}
        isParent={companyState.isParent}
        ref={childRef}
      />
    );
  } else {
    return <Forbidden />
  }
};

export default (props) => <QuickSight {...props} params={useParams()} />;