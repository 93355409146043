/**
 * システム日付を取得
 * @returns 日付
 */
export const getSystemDatetime = () => {
  return new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
  );
}

/**
 * 日付変換（YYYYMMDDhhmmss）
 * @param {String} strDateTime 日付文字列
 * @returns 日付
 */
export const getDatetimeByYmdhms = (strDateTime) => {
  const year = parseInt(strDateTime.substring(0, 4));
  const month = parseInt(strDateTime.substring(4, 6));
  const day = parseInt(strDateTime.substring(6, 8));
  const hour = strDateTime.substring(8, 10);
  const min = strDateTime.substring(10, 12);
  const sec = strDateTime.substring(12, 14);
  return new Date(year, month - 1, day, hour, min, sec);
}

/**
 * 日付変換（YYYYMMDD）
 * @param {String} strDateTime 日付文字列
 * @returns 日付
 */
export const getDateByYmd = (strDate) => {
  const year = parseInt(strDate.substring(0, 4));
  const month = parseInt(strDate.substring(4, 6));
  const day = parseInt(strDate.substring(6, 8));
  return new Date(year, month - 1, day);
}

/**
 * 日付フォーマット変換（YYYYMMDD）
 * @param {String} strDateTime 日付文字列
 * @returns 日付文字列（YYYY/MM/DD）
 */
export const formatDateYmd = (strDate) => {
  return strDate.substring(0, 4) +
    "/" +
    strDate.substring(4, 6) +
    "/" +
    strDate.substring(6, 8);
}

/**
 * 日付フォーマット変換（HHMMSS）
 * @param {String} strDateTime 日付文字列
 * @returns 変換後文字列（HH:MM:SS）
 */
export const formatDateHHmmdd = (date) => {
  let format_str = "hh:mm:ss";
  format_str = format_str.replace(/hh/g, date.getHours().toString().padStart(2, "0"));
  format_str = format_str.replace(/mm/g, date.getMinutes().toString().padStart(2, "0"));
  format_str = format_str.replace(/ss/g, date.getSeconds().toString().padStart(2, "0"));
  return format_str;
}

/**
 * 日付フォーマット
 * @param {Date} date 日付
 * @returns 変換後文字列（YYYY/MM/DD hh:mm:ss）
 */
export const formatDate = (date) => {
  let format_str = "YYYY/MM/DD hh:mm:ss";
  format_str = format_str.replace(/YYYY/g, date.getFullYear().toString());
  format_str = format_str.replace(
    /MM/g,
    (date.getMonth() + 1).toString().padStart(2, "0")
  );
  format_str = format_str.replace(
    /DD/g,
    date.getDate().toString().padStart(2, "0")
  );
  format_str = format_str.replace(
    /hh/g,
    date.getHours().toString().padStart(2, "0")
  );
  format_str = format_str.replace(
    /mm/g,
    date.getMinutes().toString().padStart(2, "0")
  );
  format_str = format_str.replace(
    /ss/g,
    date.getSeconds().toString().padStart(2, "0")
  );
  return format_str;
};

/**
 * 数値形式に変換（"/"(スラッシュ)、":"(コロン)、" "(空白スペース)を削除）
 * @param {String} date 日付文字列
 * @returns 変換後文字列
 */
export const formatDateNumber = (date) => {
  const reg = new RegExp(/\/|:|\s/, "g");
  return date.replace(reg, "");
};
