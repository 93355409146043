import * as React from "react";
import { Link } from "react-router-dom";

import { downloadGuide } from "../common/utils/FileUtils";
import { errorLog } from "../common/logger/Logger";
import { kgiPageInfoList, mainPageInfoList, monitoringInfoList } from "../_constants/PageInfoList";
import { PathInfoList } from "../_constants/PathInfoList";
import { Roll, viewId, analysisMode } from "../_constants/Code";
import { availabilityMiddleTitle, availabilitySmallTitle } from '../common/utils/AnalysisModeUtil';
import IconCompanyWhite from '../assets/img/icon/icon-company-white.svg'
import IconGroupSegmentWhite from '../assets/img/icon/icon-group-segment-white.svg'

const closeMenu = () => {
  const buttonMenu = document.getElementById("header__btn-menu");
  if (buttonMenu.classList.contains('is-active')) {
    buttonMenu.click();
  }
};

const isOpen = () => {
  let iconIsOpen = document.getElementById("iconIsOpen");
  if (iconIsOpen.classList.contains('is-open')) {
    iconIsOpen.classList.remove('is-open');
  } else {
    iconIsOpen.classList.add('is-open');
  }
};

const dashboardAvailability = (mode, authority, pageInfo) => {
  // 権限制御
  let availability = false;
  if (authority.Dashboard_AuthInfo.Availability) {  // ダッシュボードへの参照権限がある
    const authConf = authority.Dashboard_AuthInfo.Auth_Conf.find(auth => auth.Key === pageInfo.key);
    for (let i = 0; i < pageInfo.dashboardList.length; i++) {
      const middleId = pageInfo.key + '.' + String(i + 1);
      const middleFlg = availabilityMiddleTitle(mode, authConf, pageInfo.dashboardList[i].dashboard, middleId);
      if (middleFlg) {
        const smallFlg = pageInfo.dashboardList[i].dashboard.findIndex((item, idx) => availabilitySmallTitle(mode, authConf, item, middleId, idx));
        if (smallFlg !== -1) {
          availability = true;
          break;
        }
      }
    }
  }
  return availability;
}

export const infoListItems = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/info/notice" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-info"></span></div>
          <div className="nav__function-name">運営からのお知らせ</div>
        </div>
      </Link>
    </li>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/info/news" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-news"></span></div>
          <div className="nav__function-name">人的資本関連ニュース</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

export const usageGuide = (userId) => {
  const fileDownload = async (e) => {
    e.preventDefault();
    try {
      downloadGuide(process.env.REACT_APP_S3_WEB_CHROFY_COMMON, PathInfoList.Folder.guideFolder + PathInfoList.Objectkeyname.guide);
    } catch (error) {
      errorLog("E000001", userId, viewId.sideMenu, "fileDownload", error);
    }
  };
  return (
    <React.Fragment>
      <li className="nav__function-item text-h6 mt-3">
        <div>
          <a href="#" className="nav__function-link flex items-center" onClick={(e) => fileDownload(e)}>
            <div className="nav__function-icon mr-4"><span className="icon-pdf"></span></div>
            <div className="nav__function-name">ご利用ガイド [PDF]</div>
          </a>
        </div>
      </li>
    </React.Fragment>
  );
};

export const taskList = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/data/taskList" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-upload"></span></div>
          <div className="nav__function-name">データ更新</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

export const kgiSetting = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/indicator/kgi/businessPlan" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-setting-kgi"></span></div>
          <div className="nav__function-name">経営戦略KGI管理</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
  );

export const kpiTargetSetting = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/indicator/kpiSetting" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-setting-kpi"></span></div>
          <div className="nav__function-name">人的資本KPI目標設定</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

export const userList = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/user/userList" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-user"></span></div>
          <div className="nav__function-name">ユーザー権限管理</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

export const authorityList = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/auth/authorityGroupList" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-group"></span></div>
          <div className="nav__function-name">権限グループ管理</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

export const benchmarkTargetList = (
  <React.Fragment>
    <li className="nav__function-item text-h6 mt-3">
      <Link color="inherit" underline="none" component={Link} to="/benchmark/benchmarkTargetList" onClick={() => closeMenu()}>
        <div className="nav__function-link flex items-center">
          <div className="nav__function-icon mr-4"><span className="icon-graph-double"></span></div>
          <div className="nav__function-name">ベンチマーク設定</div>
        </div>
      </Link>
    </li>
  </React.Fragment>
);

const mainPageLinkContent = (pageText, pageInfo, isDisabled) => {
  return(
    <div className={`nav__category-link flex items-center py-5 ${isDisabled ? "is-diabled": ""}`}>
      <div className="nav__category-img px-6">
        <img width="64" height="64" src={pageInfo.menuIcon} alt=""/>
        <img width="64" height="64" src={pageInfo.menuIconOver} alt=""/>
      </div>
      <div className="nav__category-text flex items-center">
        <span className="nav__category-number text-h5-en">{pageText[pageInfo.key].id}</span>
        <span className="nav__category-name text-h4 ml-7 relative">{pageText[pageInfo.key].name}</span>
      </div>
    </div>
  )
};

const menuPageLinkContent = (pageText, menuInfo, isDisabled) => {
  return(
    <div className={`nav__guideline-link flex items-center ${isDisabled ? "is-disabled" : ""}`}>
      <div className="nav__guideline-icon w-8 h-8 flex items-center justify-center mr-4"><span className="icon-arrow"></span></div>
      <div className="nav__guideline-name">{pageText[menuInfo.key].id} {pageText[menuInfo.key].name}</div>
    </div>
  )
};

export const sideMenu = (userId, role, mode, isParent, authority, pageText) => (
  <React.Fragment>
    {!isParent &&
    <React.Fragment>
      {/* カテゴリー */}
      <div className="nav__category">
        <ul className="nav__category-list">
          {kgiPageInfoList.map((pageInfo, index) => {
            return (
              <li key={index} className={`nav__category-item ${!authority.haveReleaseKgi ? "nav__category-item-no-link" : ""}`}>
                <Link color="inherit" underline="none" component={Link} to={pageInfo.url} onClick={() => closeMenu()}>
                  <div className={`nav__category-link flex items-center py-5 ${!authority.haveReleaseKgi ? "is-diabled" : ""}`}>
                    <div className="nav__category-img px-6">
                      <img width="64" height="64" src={pageInfo.menuIcon} alt=""/>
                      <img width="64" height="64" src={pageInfo.menuIconOver} alt=""/>
                    </div>
                    <div className="nav__category-text flex items-center">
                      <span className="nav__category-number text-h5-en">{pageText[pageInfo.key].id}</span>
                      <span className="nav__category-name text-h4 ml-7 relative">{pageText[pageInfo.key].name}</span>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
          {mainPageInfoList.map((pageInfo, index) => {
            // 権限制御
            const availability = dashboardAvailability(mode, authority, pageInfo);
            if (availability) {
              return (
                <li key={index} className="nav__category-item">
                  <Link color="inherit" underline="none" component={Link} to={pageInfo.url} onClick={() => closeMenu()}>
                    {mainPageLinkContent(pageText, pageInfo, false)}
                  </Link>
                </li>
              )
            } else {
              return (
                <li key={index} className="nav__category-item nav__category-item-no-link">
                  {mainPageLinkContent(pageText, pageInfo, true)}
                </li>
              )
            }
          })}
        </ul>
      </div>

      {/* 月次・トピック別・法令等対応 */}
      <div className="nav__guideline pt-6 pb-8 px-6">
        <div className="nav__guideline-heading mb-6 text-h4 grey--text text--lighten-70">月次・トピック別・法令等対応</div>
        <ul className="nav__guideline-list pl-5">
          {monitoringInfoList.map((menuInfo, index) => {
            // 権限制御
            const availability = dashboardAvailability(mode, authority, menuInfo);
            if (availability) {
              return (
                <li key={index} className="nav__guideline-item text-h6 mt-3">
                  <Link color="inherit" underline="none" component={Link} to={menuInfo.url} onClick={() => closeMenu()}>
                    {menuPageLinkContent(pageText, menuInfo, false)}
                  </Link>
                </li>
              )
            } else {
              return (
                <li key={index} className="nav__guideline-item nav__guideline-item-no-link text-h6 mt-3">
                  {menuPageLinkContent(pageText, menuInfo, true)}
                </li>
              )
            }
          })}
        </ul>
      </div>

      {/* ダッシュボード */}
      <div className="nav__dashboard pt-5 pb-10 px-11">
        <ul className="nav__dashboard-list">
          <li className="nav__dashboard-item text-h6 mt-3">
            <Link color="inherit" underline="none" component={Link} to="/dashboard/myDashboard" onClick={() => closeMenu()}>
              <div className="nav__dashboard-link flex items-center">
                <div className="nav__dashboard-icon mr-4"><span className="icon-bookmark-off"></span></div>
                <div className="nav__dashboard-name">マイダッシュボード</div>
              </div>
            </Link>
          </li>
          <li className="nav__dashboard-item text-h6 mt-3">
            <Link color="inherit" underline="none" component={Link} to="/dashboard/dashboardList" onClick={() => closeMenu()}>
              <div className="nav__dashboard-link flex items-center">
                <div className="nav__dashboard-icon mr-4"><span className="icon-dashboard"></span></div>
                <div className="nav__dashboard-name">ダッシュボード一覧</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>

      {/* その他 */}
      <div className="nav__function pt-5 pb-10 px-11">
        <ul className="nav__function-list">
          {infoListItems}
          {usageGuide(userId)}
        </ul>
      </div>
      <div className="nav__function pt-5 pb-16 px-11">
        <ul className="nav__function-list">
          {authority.Upload_AuthInfo.Auth_Conf.View && taskList}
          {authority.Kgi_AuthInfo.Auth_Conf.View && kgiSetting}
          {authority.Kpi_AuthInfo.Auth_Conf.View && kpiTargetSetting}
          {role === Roll.administrator && userList}
          {role === Roll.administrator && authorityList}
          {benchmarkTargetList}
        </ul>
      </div>
    </React.Fragment>
    }
    {isParent &&
    <React.Fragment>
      <div id="iconIsOpen" className={`nav__dropdown ${mode == analysisMode.standalone ? ' is-single' : ' is-multiple'} is-open`}>
        {mode == analysisMode.standalone && 
        <button className="nav__dropdown-button" onClick={() => isOpen()}>
          <span className="nav__dropdown-icon"><img width="24" height="24" src={IconCompanyWhite} alt="" /></span>
          <span className="nav__dropdown-title">単体分析</span>
        </button>
        }
        {mode != analysisMode.standalone && 
        <button className="nav__dropdown-button" onClick={() => isOpen()}>
          <span className="nav__dropdown-icon"><img width="24" height="24" src={IconGroupSegmentWhite} alt="" /></span>
          <span className="nav__dropdown-title">連結&セグメント分析</span>
        </button>
        }
        <div className="nav__dropdown-content-wrapper">
          <div className="nav__dropdown-content">
            {/* カテゴリー */}
            <div className="nav__category">
              <ul className="nav__category-list">
                {kgiPageInfoList.map((pageInfo, index) => {
                  return (
                    <li key={index} className={`nav__category-item ${!authority.haveReleaseKgi ? "nav__category-item-no-link" : ""}`}>
                      <Link color="inherit" underline="none" component={Link} to={pageInfo.url} onClick={() => closeMenu()}>
                        <div className={`nav__category-link flex items-center py-5 ${!authority.haveReleaseKgi ? "is-diabled" : ""}`}>
                          <div className="nav__category-img px-6">
                            <img width="64" height="64" src={pageInfo.menuIcon} alt=""/>
                            <img width="64" height="64" src={pageInfo.menuIconOver} alt=""/>
                          </div>
                          <div className="nav__category-text flex items-center">
                            <span className="nav__category-number text-h5-en">{pageText[pageInfo.key].id}</span>
                            <span className="nav__category-name text-h4 ml-7 relative">{pageText[pageInfo.key].name}</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  )
                })}
                {mainPageInfoList.map((pageInfo, index) => {
                  // 権限制御
                  const availability = dashboardAvailability(mode, authority, pageInfo);
                  if (availability) {
                    return (
                      <li key={index} className="nav__category-item">
                        <Link color="inherit" underline="none" component={Link} to={pageInfo.url} onClick={() => closeMenu()}>
                          {mainPageLinkContent(pageText, pageInfo, false)}
                        </Link>
                      </li>
                    )
                  } else {
                    return (
                      <li key={index} className="nav__category-item nav__category-item-no-link">
                        {mainPageLinkContent(pageText, pageInfo, true)}
                      </li>
                    )
                  }
                })}
              </ul>
            </div>

            {/*
              ■ nav__guideline-item の非活性表示について
              nav__guideline-link クラスを持つa要素に is-disabled クラスを入れてください
            */}
            {/* 月次・トピック別・法令等対応 */}
            <div className="nav__guideline pt-6 pb-8 px-6">
              <div className="nav__guideline-heading mb-6 text-h4">月次・トピック別・法令等対応</div>
              <ul className="nav__guideline-list pl-5">
                {monitoringInfoList.map((menuInfo, index) => {
                  // 権限制御
                  const availability = dashboardAvailability(mode, authority, menuInfo);
                  if (availability) {
                    return (
                      <li key={index} className="nav__guideline-item text-h6 mt-3">
                        <Link color="inherit" underline="none" component={Link} to={menuInfo.url} onClick={() => closeMenu()}>
                          {menuPageLinkContent(pageText, menuInfo, false)}
                        </Link>
                      </li>
                    )
                  } else {
                    return (
                      <li key={index} className="nav__guideline-item nav__guideline-item-no-link text-h6 mt-3">
                        {menuPageLinkContent(pageText, menuInfo, true)}
                      </li>
                    )
                  }
                })}
              </ul>
            </div>

            {/* ダッシュボード一覧 */}
            <div className="nav__dashboard pt-9 pb-9 px-11">
              <ul className="nav__dashboard-list">
                <li className="nav__dashboard-item text-h6">
                  <Link color="inherit" underline="none" component={Link} to="/dashboard/dashboardList" onClick={() => closeMenu()}>
                    <div className="nav__dashboard-link flex items-center">
                      <div className="nav__dashboard-icon mr-4"><span className="icon-dashboard"></span></div>
                      <div className="nav__dashboard-name">ダッシュボード一覧</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>

            {/* マイダッシュボード */}
            {mode == analysisMode.standalone && 
            <div className="nav__dashboard pt-9 pb-9 px-11">
              <ul className="nav__dashboard-list">
                  <li className="nav__dashboard-item text-h6">
                    <Link color="inherit" underline="none" component={Link} to="/dashboard/myDashboard" onClick={() => closeMenu()}>
                      <div className="nav__dashboard-link flex items-center">
                        <div className="nav__dashboard-icon mr-4"><span className="icon-bookmark-off"></span></div>
                        <div className="nav__dashboard-name">マイダッシュボード</div>
                      </div>
                    </Link>
                  </li>
              </ul>
            </div>
            }
          </div>
        </div>
      </div>
            
      {/* その他 */}
      <div className="nav__function pt-5 pb-10 px-11">
        <ul className="nav__function-list">
          {infoListItems}
          {usageGuide(userId)}
        </ul>
      </div>
      <div className="nav__function pt-5 pb-16 px-11">
        <ul className="nav__function-list">
          {authority.Upload_AuthInfo.Auth_Conf.View && taskList}
          {authority.Kgi_AuthInfo.Auth_Conf.View && kgiSetting}
          {authority.Kpi_AuthInfo.Auth_Conf.View && kpiTargetSetting}
          {role === Roll.administrator && userList}
          {role === Roll.administrator && authorityList}
          {benchmarkTargetList}
        </ul>
      </div>
    </React.Fragment>
    }
  </React.Fragment>
);
